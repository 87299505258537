<script setup lang="ts">
 const {t,locale,availableLocales, setLocale} = useI18n()

 const locales = computed(()=>{
    return availableLocales.map((locale)=>{
        return {name: locale.toUpperCase(), code: locale}
    })
 })

 const initSettingsStore = useDefaultSettingsStore()

 const changeLocale = (value:string)=>{
    setLocale(value)    
    document.cookie =`i18n_locale=${value};`
    const {post} = useOFetchCustom('change-language')

    post({language:value},{
        onSuccess: (response) => {
            
        }})
 }   


</script>

<template>
    <q-select :options="locales" v-model="locale" map-options emit-value :option-label="'name'"
               square 
               dense 
               borderless
               options-dense
              :option-value="'code'" 
              @update:model-value="changeLocale">
        <template v-slot:selected>
            <div class="tw-text-gray-500">
                {{ locale.toUpperCase() }}
            </div>
        </template>
    </q-select>
</template>