import type { ShopCart } from "~/models/ShopCart";

export const useShopCartStore = defineStore('shop_cart_store', {
    state: (): {shopCart: ShopCart, loadingShopCart: boolean} => ({
        shopCart: {
            items: [],
            count: 0,
            subtotal: 0,
            subtotal_formatted: '',
            shipment_formatted: '',
            to_pay_formatted: '',
            tax_formatted: '',
            is_wholesaler: false,
            storage_id: 0
        },
        loadingShopCart: false
    }),
    actions: {
        async fetchShopCart(headers?: Record<string, string|number>) {
            const {get: fetchCart} = useOFetchCustom('shop-cart-data')
            this.loadingShopCart = true

            fetchCart(headers, {
                onSuccess: ({data}) => {
                    this.shopCart = data
                    this.loadingShopCart = false
                },
                onError: () => { 
                    this.loadingShopCart = false
                }
            })
        },
        async addToCart(body: any): Promise<any> {
            const {post} = useOFetchCustom('cart')
            return new Promise((resolve, reject) => {
                post(body, {
                    onSuccess: (res: any) => {
                        this.fetchShopCart({'storage_id': JSON.parse(localStorage.getItem('storeSelected')).id})

                        resolve(res)
                    },
                    onError: (error: any) => {

                        reject(error)
                    }
                })
            })

        }
    }
})
