<template>
    <!--Login link-->
    <NuxtLink class="tw-inline tw-text-black hover:tw-text-primary-default tw-transition font-right-navbar" to='/auth/login' v-if="!user">
        <div class="tw-flex tw-flex-row sm:tw-flex-col tw-gap-1 tw-items-center tw-text-gray-300 tw-font-signika tw-text-base tw-font-normal">
        <q-icon name="person" size="36px"></q-icon>
            <span>{{ $t('login.register') }}</span>
        </div>
    </NuxtLink>


    <div class="tw-flex tw-flex-row sm:tw-flex-col tw-gap-1 tw-items-center tw-text-gray-300 tw-font-signika tw-text-base tw-font-normal !tw-cursor-pointer" v-if="user">
            <q-icon name="person" size="36px"></q-icon>
            <span class="tw-text-primary-default">{{ user.name }}</span>

            <q-menu auto-close>
                <q-list style="min-width: 150px" class="font-right-navbar">
                    <q-item>
                        <q-item-section avatar>
                            <q-icon name="person" />
                        </q-item-section>
                        <q-item-section class="font-right-navbar">
                            {{ user?.name }}
                        </q-item-section>
                    </q-item>
                    <q-item clickable @click="profile" class="font-right-navbar">
                        <q-item-section class="font-right-navbar">{{ $t('nav.profile') }}</q-item-section>
                    </q-item>
                    <template v-for="p in profile_menu">
                        <q-item clickable v-if="canShow(p)" @click="goTo(p.route!)">
                            <q-item-section>{{ $t(p.title) }}</q-item-section>
                        </q-item>
                    </template>
                    <q-separator />
                    <q-item clickable @click="logout" class="font-right-navbar">
                        <q-item-section class="font-right-navbar">{{ $t('actions.logout') }}</q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
    </div>
</template>

<script setup lang="ts">
import {profile_menu} from "../common/main_menu";
import type { MenuItem } from "../models/MenuItem";
import { can } from "../common/helpers";
import type { User } from "~/models/User";


const user = useSanctumUser<User>()
const {logout: lout} = useSanctumAuth()
const shopCartStore = useShopCartStore()

const router = useRouter();
const model_menu:Ref<boolean> = ref(false)

function login() {
    router.push('/auth/login');
}

function profile() {
    router.push('/profile/edit')
}

async function logout() {
    await lout()
    shopCartStore.fetchShopCart()
}

function goTo(route_name: string){
    const process_route = (route_name: string) => {
        if (route_name.includes('#')) {
            return '/'+route_name
        }
    }
}


function canShow(item: MenuItem)
{
    //return can(item?.can ?? null, props)
    return can()
}

</script>

<style lang="css">
    .profile-account .material-icons {
        font-size: 36px !important; /* Ajusta el tamaño según sea necesario */
        color: #26A69A /* Cambia el color según sea necesario */
    }
</style>
