<template>
    <q-list>
        <component
            v-for="(item, i) in menu"
            :is="hasChildren(item) ? QExpansionItem : QItem"
            v-ripple="! hasChildren(item)"
            :clickable="! hasChildren(item)"
            @click="goTo(item)"
            :label="$t(item.title)"
            :icon="item.icon"
            :header-class="'expandable-item font-left-menu'"
            :modelValue="expands.includes(item.title)"
            :class="{'tw-bg-blue-50': item.route == menuItemSelectedStore.routeSelected}"
        >
            <q-item-section avatar v-if="! hasChildren(item)" class="font-left-menu">
                <q-icon
                    :name="item.icon"
                    />
            </q-item-section>
            <q-item-section class="font-left-menu"  v-if="! hasChildren(item)">{{ $t(item.title) }}</q-item-section>

      <LeftMenu class="tw-ml-3" :menu="useMenu(item.children!)" v-if="hasChildren(item) "/>
    </component>
  </q-list>
</template>

<script setup lang="ts">

import type {MenuItem} from "../models/MenuItem";
import {QItem, QExpansionItem} from "quasar";
import {useMenu} from "../composables/LefMenu";
import { storeToRefs } from 'pinia';

const props = defineProps<{
  menu: MenuItem[]
}>()


const menuItemSelectedStore = useMenuItemSelectedStore()

const {expands} = storeToRefs(menuItemSelectedStore)

function hasChildren(item: MenuItem): boolean {

  if(item.children && item.children?.length > 0) return true; else return false;
}

const router = useRouter();

function goTo(item: MenuItem) {

  if (item.route) {
    router.push(item.route)
  }
}
</script>
