import { getCategories } from '../composables/getCategories';
export const useSearchStore = defineStore('searchStore', {
    state: () => ({
        query: '',
        category:null
    }),
    getters: {
        getQuery(state) {
            return state.query
        },
        getCategory(state){
            return state.category
        }
    },
    actions: {
        setQuery(newQuery: any) {
            this.query = newQuery;
        },
        setCategory(newCategory: any){
            this.category = newCategory
        }
    },
})