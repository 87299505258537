<template>
    <q-select
        v-model="coin"
  
        :options-dark="false"
        options-dense
        square 
        dense
        borderless
        :options="props.coins_availables"
         >
         <template v-slot:selected>
            <div class="tw-text-gray-500">
                {{ coin }}
            </div>
         </template>
         </q-select>
</template>
<script setup lang="ts">
import {inject, onMounted, ref} from 'vue';

const emitter = inject('emitter')

const props = defineProps({
    system_coin: String,
    coins_availables: []
});

const coin = computed({
    get:()=>props.system_coin,
    set:(v)=>{
        
    }
})

const change = (value:any) => {
    // router.post(route('set-system-coin'), {coin: value},
    // {
    //     preserveScroll: true,
    //     onSuccess: () => {
    //         emitter.emit('update:SystemCoin')
    //     }
    // })
}

onMounted(()=>{
    // emitter.on('update:SystemCoin',()=>{
    //     coin.value = props.system_coin
    // })
})


</script>
