<template>
    <div class="tw-w-full tw-sticky tw-top-0 tw-z-20" id="nav">
      <q-scroll-area style="height: 45px; min-width: 100px;" v-if="user?.role != null">
        <div class="home-nav-px tw-py-1 tw-flex tw-gap-2 tw-text-sm tw-bg-black tw-text-white admin-top-menu tw-relative" >
          <AdminTopMenu :menu="filtered_main_menu"></AdminTopMenu>
        </div>
      </q-scroll-area>
      <NavHeader/>
       <nav class="tw-w-full home-nav-px tw-py-4 tw-bg-white tw-flex tw-justify-between tw-items-center tw-z-20 tw-relative">
        <!-- Mobile Menu Button -->
        <slot name="drawerBtn">
        
      </slot>
        <NuxtLink to="/store" >
          <ApplicationLogo></ApplicationLogo>
        </NuxtLink>

        <ShopCartButton v-if="shouldShowMobileMenu"></ShopCartButton>
 
        <!-- Desktop Menu (768px and larger) -->
        <div class="tw-hidden md:tw-block tw-w-[35rem]" v-if="!isMenuOpen && !shouldShowMobileMenu">
                <BuscadorHome />
         </div>
        <div v-if="!isMenuOpen && !shouldShowMobileMenu" class="lg:tw-flex ">
           <HomeMenu />
        </div>

        <!-- Mobile Menu 768px -->

      </nav>

      <HomeSubHeader class="tw-hidden md:tw-flex tw-bg-primary-700" ></HomeSubHeader>
      <div class="tw-bg-primary-default tw-p-4 tw-block md:tw-hidden tw-z-10 tw-relative ">
        <BuscadorHome />
      </div>
    </div>
  </template>

  <script lang="ts" setup>
  import HomeMenu from '../components/HomeMenu.vue';
  import HomeSubHeader from './HomeSubHeader.vue';
  import BuscadorHome from './BuscadorHome.vue';
  import {ref, onMounted, onUnmounted, computed} from 'vue';
  import type {MenuItem} from "../models/MenuItem";
  import { can } from '../common/helpers';
  import { useFilteredMenu, useLeftMenu } from '../composables/LefMenu';
  import NavHeader from './NavHeader.vue';
  import ApplicationLogo from './ApplicationLogo.vue';
  import type { User } from '~/models/User';


  const isMenuOpen = ref(false);

  const screenWidth = ref(800);

  const filtered_main_menu = useFilteredMenu()
  

  const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
  };

  const user = useSanctumUser<User>()

  const shouldShowMobileMenu = ref(screenWidth.value < 745);

  const handleResize = () => {
    screenWidth.value = window.innerWidth;
    shouldShowMobileMenu.value = screenWidth.value < 745;
  };

  onMounted(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  const closeMenu = () => {
    if (isMenuOpen.value) {
      toggleMenu();
    }
  };
  const router = useRouter();

  const authenticated = computed(() => {
    return user.value?.id
  })
  async function profile() {
    router.push('/profile/edit')
  }
  function logout() {
    router.push('/logout')
  }

  function goTo(route_name: string){
    router.push(route_name);
  }
  function canShow(item: MenuItem)
  {
    //return can(item?.can ?? null, user.value)
    return can()
  }


  </script>

  <style scoped>
    .carrito-container {
      position: relative;
      display: inline-block;
  }

  .carrito-cantidad {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #0674BF;
      color: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 0px 4px;
      text-align: center;
      font-size: 12px;

      display: flex;
      justify-content: center;
      align-items: center;
  }

  .buscador {
    margin-top: -0.2rem !important;
  }

  .buscador input{
    border-radius: none;
  }

    </style>
